.workfolow {
	width: 100%;
	overflow: hidden;
}

.left-block {
    width: 250px;
    position: absolute;
    height: 100%;
    z-index: 50;
    overflow-y: auto;
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 50px;
    box-shadow: 6px 0 20px -5px rgba(0, 0, 0, 0.8), 10px 10px 15px -10px rgba(0, 0, 0, 0.8);
}

.content-block {
	width: 100%;
    height: 100%;
    position: absolute;
    margin-left: 250px;
    padding-top: 15px;
    padding-right: 260px;
    padding-bottom: 75px;
    overflow-y: auto;
}

.left-block .filters-block {
    height: 40px;
    overflow: hidden;
}

.left-block .filters-block.open {
    height: 450px;
}

.left-block .hover:hover {
    color: #F00;
}

.total {
    color:#F00;
}

