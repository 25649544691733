::-webkit-scrollbar-thumb:hover {
    background-color: #FF0000 !important;
}
::-webkit-scrollbar-thumb {
    background-color: #C3C3C3 !important;
}
::-webkit-scrollbar {
    width: 5px !important; 
    height: 5px !important;
}
::-webkit-scrollbar-track {
    /* background-color: #eaeaea; */
    /* border-left: 1px solid #ccc; */
}

.fa {
    padding: 0 4px;
}

a, a:visited {
    color: #000;
}

.btn-primary .text a {
    color: #fff;
}

.active-icon {
    color: #F00; 
}

.total {
    color:#F00;
}

body {
    overflow: hidden;
}

div.browser label {
    background-color: #5a7bc2;
    padding: 5px 15px;
    color: white;
    padding: 6px 0px;
    font-size: 40%;
    font-weight: bold;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    display: block;
    width: 300px;
    margin: 20px auto 0px auto;
    box-shadow: 2px 2px 2px #888888;
    text-align: center;
    font-size: 15px;
}

div.browser span {
    cursor: pointer;
}

div.browser input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: solid transparent;
    border-width: 0 0 100px 200px;
    opacity: .0;
    filter: alpha(opacity= 0);
    -o-transform: translate(250px,-50px) scale(1);
    -moz-transform: translate(-300px,0) scale(4);
    direction: ltr;
    cursor: pointer;
}

.demo-panel-files {
    text-align: center;
}

#workfolow {
	/*margin-top: -20px;*/
}

#fake-map {
    min-width: 100px;
    min-height: 100px;
    position: absolute;
    background-color: #fff;
    top: 0px;
    z-index: -1;
}

.layout {
    position: absolute;
    z-index: 50;
    width: 100%;
}

.line-z {
    z-index: 50;
    box-shadow: 0px 6px 10px -3px rgba(0,0,0,0.5);
}

.pull-center {
    text-align: center;
}

#header {
    min-height: 50px;
    position: absolute;
    width: 100%;
    padding: 10px 35px 0 35px;
    z-index: 500;
    padding-left: 0;
    left: 40px;
}

#content {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

#layout-block {
    position: absolute;
    top: 75px;
    right: 50px;
    width: 350px;
    min-height: 150px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,.2);
    z-index: 50;
    max-height: 90vh;
    overflow: auto;
}

#lg-layout-block {
    position: absolute;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 3px 1px rgba(0,0,0,.2);
    z-index: 50;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding-top: 50px;
    overflow: hidden;
}

#layout-block.minh {
    min-height: 85vh;
}

#button-edit-applications {
    padding: 2px 7px;
}

button#items-takeout {
    color: #000;
}

#layout-block-fake-route {
    position: absolute;
    top: 75px;
    right: 100px;
    width: 350px;
    padding: 15px;
    z-index: 49;
    max-height: 85vh;
    overflow: auto;
    min-height: 85vh;
}

ul#routList {
    max-height: 70vh;
    overflow: auto;
}

#layout-block-fake-route:hover {
    margin-right: 260px;
    transition-property: margin-right;
    transition-duration: 600ms;
    transition-timing-function: linear;
}

.header-nav {
    position: absolute;
    z-index: 50;
}

#button-new-application, {
    left: 50px;
}

ul.dropdown-menu {
    min-width: 250px;
}


#users-dropdown ul, #menu-dropdown ul, .filter-header ul {
    margin-top: 10px;
    margin-left: 10px;
}

.menu-header ul {
    text-align: center;
    padding: 10px; 
}

.menu-header ul li{
    display: inline-block;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 2px;
}

.filter-header ul {
    padding: 10px;
}

.filter-header ul li {
    display: inline;
    padding: 5px 10px;
    text-align: center;
    border-radius: 2px; 
}

.menu-header ul li:hover {
    background-color: #EAEAEA;
}

.user-header {
    text-align: center;
}

.btn-close-items {
    position: absolute;
    right: 20px;
    margin-top: -1px;
}

.search-list {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 3px 3px 1px rgba(0,0,0,0.4);
    z-index: 50;
    width: 300px;
    margin-left: 1px;
}

.search-list ul, #application-tab-content ul,
#layout-block-fake-route ul, ul#routList, #filters-application-tab-content ul,
ul#delivery-delect-list{
    padding: 0;
}

#delivery-delect-list .close {
    padding-right: 8px;
} 

.search-list ul li, #application-tab-content ul li,
#layout-block-fake-route ul li, ul#routList li,
ul#delivery-delect-list li {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
}

#application-tab-content ul,  #filters-application-tab-content ul, ul#delivery-delect-list{
    max-height: 75vh;
    overflow: auto;
}

 #layout-block-fake-route ul {
    margin-top: 100px;
    max-height: 70vh;
    overflow: auto; 
 }

#application-tab-content ul li, #layout-block-fake-route ul li,
ul#routList li, ul#delivery-delect-list li{
    padding: 5px 10px 5px 0;
}

#application-tab-content ul li div, #layout-block-fake-route ul li div, 
ul#routList li div, ul#delivery-delect-list li div{
    width: 100%;    
    margin-right: -75px;
    box-shadow: 2px -2px 5px 0 rgba(0,0,0,.1), -2px -2px 5px 0 rgba(0,0,0,.1), 2px 2px 5px 0 rgba(0,0,0,.1), -2px 2px 5px 0 rgba(0,0,0,.1);
    padding-left: 10px;
    padding-top: 3px;
    min-height: 50px;
    font-size: 12px;
    //height: 72px;
    //overflow: hidden;
}

#layout-block-fake-route ul li div {
    background-color: #FFF;
}

#application-tab-content ul li div span, #layout-block-fake-route ul li div span, 
ul#routList li div span, ul#delivery-delect-list li div span {
    padding-left: 5px;
}

#application-tab-content ul li:hover div, #layout-block-fake-route ul li:hover div,
ul#delivery-delect-list li:hover div {
    //border-bottom: 1px solid #FF0000;
}

#application-tab-content ul li div.delivery, #layout-block-fake-route ul li div.delivery, 
ul#routList li div.delivery, ul#delivery-delect-list li div.delivery {
    border-left: 10px solid #FF8100; 
}

#application-tab-content ul li div.takeout, #layout-block-fake-route ul li div.takeout, 
ul#routList li div.takeout, ul#delivery-delect-list li div.takeout {
    border-left: 10px solid #27AD05;
}

.search-list ul li:hover {    
    background-color: #F9BF62;
}

.tekeout-order-add {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 15px;
}

.tekeout-order-add li {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid rgba(0,0,0,0.2);
}

.tekeout-order-add li .part-takeout-head{
    max-height: 30px;
    background-color: #F9f9f9;
    padding-top: 2px;
    padding-left: 15px;
    padding-right: 30px;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

.tekeout-order-add li .part-takeout-head:hover {
    background-color: #F7F7F7;
    box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
}

.tekeout-order-add li .part-takeout-block {
    padding: 5px;
    /* display: none; */
}

.tekeout-order-add li .part-takeout-block .open,
.tekeout-order-add li .part-takeout-head .open {
    box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
}

.font-20-bold {    
    font-size: 20px;
    font-weight: 600;
}

#error-input {
    font-size: 20px;
    color: #F00;
}

.select-application, #layout-block-fake-route ul li div.select-application {
    background-color: #89FF72 !important;
    height: 100% !important;
}

span.items-check-sum {
    font-size: 10px;
    color: #333;
}

span.items-check-sum::after {
    margin-left: 4px;
    content: 'руб.';
}

.error {
    color: #F00 !important;
}

span.items-check-sum.error::after {
    content: '';
}



/*STATUS COLOR*/
.color-status-new {
    background-color: rgba(255, 255, 255, 0.2);
}
.color-status-progress {
    background-color: rgba(255,0,0,0.40);
}
.color-status-item_deliveryboy {
    background-color: rgba(255, 235, 0, 0.2);
}
.color-status-completed, .color-status-taken, .color-status-delivered,
.color-status-taken_part, .color-status-delivered_part  {
    background-color: rgba(42, 255, 0, 0.2);
}
.color-status-instock {
    background-color: rgba(0, 140, 255, 0.2);
}
.color-status-denial_place, .color-status-take_denial_place, .color-status-delivery_denial_place {
    background-color: rgba(50 , 0, 255, 0.2);
}
.color-status-denial_phone, .color-status-take_denial_phone, .color-status-delivery_denial_phone {
    background-color: rgba(130, 0, 255, 0.2);
}
.color-status-canceled {
    background-color: rgba(150, 150, 150, 0.2);
}
.color-status-transfer_denial_place{
    background-color: rgba(80, 80, 80, 0.3);
}
.color-status-transfer_denial_phone {
    background-color: rgba(80, 80, 80, 0.3);
}

.color-fillbg-new {
    background-color: rgb(0, 0, 0);
    color: white;
}
.color-fillbg-progress {
    background-color: rgb(255,0,0);
    color: white;
}
.color-fillbg-item_deliveryboy {
    background-color: rgb(255, 235, 0);
}
.color-fillbg-completed, .color-fillbg-taken, .color-fillbg-delivered,
.color-fillbg-taken_part, .color-fillbg-delivered_part  {
    background-color: rgb(42, 255, 0);
}
.color-fillbg-instock {
    background-color: rgb(0, 140, 255);
    color: white;
}
.color-fillbg-denial_place, .color-fillbg-take_denial_place, .color-fillbg-delivery_denial_place {
    background-color: rgb(50 , 0, 255);
    color: white;
}
.color-fillbg-denial_phone, .color-fillbg-take_denial_phone, .color-fillbg-delivery_denial_phone {
    background-color: rgb(130, 0, 255);
    color: white;
}
.color-fillbg-canceled {
    background-color: rgb(150, 150, 150);
    color: white;
}
.color-fillbg-transfer_denial_place{
    background-color: rgb(80, 80, 80);
    color: white;
}
.color-fillbg-transfer_denial_phone {
    background-color: rgb(80, 80, 80);
    color: white;
}

.color-border-new {
    border: 3px solid rgb(0, 0, 0);
}
.color-border-progress {
    border: 3px solid rgb(255,0,0);
}
.color-border-item_deliveryboy {
    border: 3px solid rgb(255, 235, 0);
}
.color-border-completed, .color-border-taken, .color-border-delivered,
.color-border-taken_part, .color-border-delivered_part  {
    border: 3px solid rgb(42, 255, 0);
}
.color-border-instock {
    border: 3px solid rgb(0, 140, 255);
}
.color-border-denial_place, .color-border-take_denial_place, .color-border-delivery_denial_place {
    border: 3px solid rgb(50 , 0, 255);
}
.color-border-denial_phone, .color-border-take_denial_phone, .color-border-delivery_denial_phone {
    border: 3px solid rgb(130, 0, 255);
}
.color-border-canceled {
    border: 3px solid rgb(150, 150, 150);
}
.color-border-transfer_denial_place{
    border: 3px solid rgb(80, 80, 80);
}
.color-border-transfer_denial_phone {
    border: 3px solid rgb(80, 80, 80);
}
/*STATUS COLOR*/
.color-status-text-new {
    color: rgb(255, 255, 255);
}
.color-status-text-progress {
    color: rgb(255,0,0);
}
.color-status-text-item_deliveryboy {
    color: #fff900;
}
.color-status-text-completed {
    color: #8cff6f;
}
.color-status-text-instock {
    color: rgb(0, 140, 255);
}
.color-status-text-denial_place {
    color: rgb(50 , 0, 255);
}
.color-status-text-denial_phone {
    color: rgb(130, 0, 255);
}
.color-status-text-canceled {
    color: rgb(150, 150, 150);
}
/*END*/

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: #000;
}

.ui.label.empty.circular.small-size-label {
    width: 0.1em;
    height: 0.1em;
    font-size: 5px;
    margin-bottom: 3px;
    margin-right: 5px;
}

.layout-left {
    width: 250px;
    position: absolute;
    height: 100%;
    z-index: 50;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 5px;
    padding-bottom: 50px;
    box-shadow: 6px 0 20px -5px rgba(0, 0, 0, 0.8), 10px 10px 15px -10px rgba(0, 0, 0, 0.8);
}

.sub-left {
    margin-left: 250px;
    background-color: #fff;
    z-index: 49;
}

.layout-content {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: 500px;
    box-shadow: 6px 35px 20px 30px rgba(0, 0, 0, 0.8), 10px 10px 15px -10px rgba(0, 0, 0, 0.8);
    padding-top: 15px;
    padding-bottom: 75px;
    overflow-y: auto;
}

.block-header-fix {
    margin-top: -50px;
    padding-top: 50px;
}

.setting-baloon {
    position: absolute !important;
    z-index: 49;
    top: 280px;
    left: 5px;
}
.setting-road {
    position: absolute !important;
    z-index: 49;
    top: 315px;
    left: 5px;
    color: #FFF !important;
}

.setting-print {
    position: absolute !important;
    z-index: 49;
    top: 350px;
    left: 5px;
    color: #FFF !important;
}

@media print {
    table {
        width: 100%;
        border-collapse: collapse;
        position: absolute;
        top: 0px;
        left: 0px;
    }
    th, td {
        border: 1px solid;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
    }
    .table-print {
        display: block;
        font-size: 12px;
    }
}

div#tools-service-applic {
    box-shadow: none !important;
    border: none;
    left: -85px;
    width: 50px !important;
}

div#tools-service-applic-table {
    box-shadow: none !important;
    border: none;
    left: 0;
    width: 50px !important;
}

div#tools-service-applic button {
    font-size: 12px;
    padding: 8px;
}

div#tools-service-applic-table button {
    font-size: 12px;
    padding: 8px;
}

div#tools-service-applic .dropdown-menu {
    margin-left: -200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7)
} 

div#tools-service-applic-table .dropdown-menu {
    //margin-left: -200px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
    left: 0;
} 

.dropdown-menu > li > .itemAcitonApplic:hover {
    background-color: #e1e3e9;
    color: #333;
}